@import "../_sprite";

$sidebar-width: 200px;
$sidebar-visible-width: 80px;


#vertical-navigation {
	position: absolute;
	right: 0;
	z-index: 40;
	width: $sidebar-width;
	display: flex;
	flex-direction: column;
	height: calc(100% - 111px);
	overflow-x: hidden;

	a {
		color: white;

		&:hover {
			text-decoration: none;
		}
	}


	.menu-item a,
	.social-share {
		width: $sidebar-width;
		margin-right: 0;
		background: map-get($theme-colors, secondary);

		transform: translateX($sidebar-width - $sidebar-visible-width);

		&:hover {
			transform: translateX(0);
		}
	}


	.social-share {
		min-height: 168px;
	}

	.social-share-text {
		transform: rotate(270deg);
		text-align: center;
		text-transform: uppercase;
		color: white;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $sidebar-visible-width;
	}

	#menu-vertical-nav,
	#menu-vertical-nav-it,
	#menu-vertical-nav-eng {

		a {
			position: relative;
			display: flex;
			padding: 1rem;
			align-items: center;
			justify-content: space-between;
			height: 70px;
			padding-left: $sidebar-visible-width;

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: $sidebar-visible-width * 0.5;

				transform: translate(-50%, -50%);
			}
		}
	}

	&.fixed-top {
		position: fixed;
		left: auto;
	}

	.widget_polylang {
		background-color: map-get($theme-colors, secondary);
		transform: translateX($sidebar-width - $sidebar-visible-width);

		ul {
			padding: 0;
			margin: 0;
			border-bottom: solid 1px darken(map-get($theme-colors, secondary), 10%);
		}
	}

	#menu-vertical-nav a,
	#menu-vertical-nav-it a,
	#menu-vertical-nav-eng a,
	.social-share {
		transition: transform 0.2s ease;
		border-bottom: solid 1px darken(map-get($theme-colors, secondary), 10%);
	}

	.lang-item {
		text-align: center;
		width: $sidebar-visible-width;
		padding: 0 0 map-get($spacers, 3) 0;
		font-weight: 700;

		&:first-child {
			padding-top: map-get($spacers, 3);
		}

		a {

			&:hover {
				color: map-get($theme-colors, primary);
			}
		}

		&.current-lang a {
			color: map-get($theme-colors, primary);
		}
	}

	.widget_custom_html {
		flex-grow: 1;
	}

	.social-share-buttons li {
		margin: 0 0 map-get($spacers, 2) 0;
		padding: 0;

		&:first-child {
			margin-top: map-get($spacers, 2);
		}
	}

	.addthis_default_style .at300b {
		margin: 0;
		padding: 0;
		height: 32px;
	}
}

.widget_search input[type="submit"] {
	width: $sidebar-visible-width;
	z-index: 4;
}

.admin-bar #vertical-navigation {
	height: calc(100% - 32px - 111px);

	&.fixed-top {
		height: calc(100% - 32px);
		top: 32px;
	}
}

.social-share {
	width: $sidebar-visible-width * 2;
}

.social-share-buttons {
	width: $sidebar-visible-width;
}

#menu-vertical-nav,
#menu-vertical-nav-it,
#menu-vertical-nav-eng {
	padding-left: 0;
	margin-bottom: 0;

	li {

	}
}

.menu-promotion,
.menu-promozione {

	a::before {
		@include sprite($promotion);
	}
}

.menu-transports,
.menu-trasporti {

	a::before {
		@include sprite($transports);
	}
}

.menu-programmes-europeens,
.menu-programmi-europei,
.menu-european-programs {

	a::before {
		@include sprite($programmes-europeens);
	}
}

.menu-innovation,
.menu-innovazione {

	a::before {
		@include sprite($innovation);
	}
}

.menu-tourisme,
.menu-turismo,
.menu-tourism {

	a::before {
		@include sprite($tourisme);
	}
}

// .menu-transition-energetique a:before {
//   background-image: url(../images/sprite.png);
//   background-position: -150px -74px;
//   width: 29px;
//   height: 35px;
// }
// .menu-culture a:before {
//   background-image: url(../images/sprite.png);
//   background-position: -100px -51px;
//   width: 38px;
//   height: 37px;
// }
// .menu-ecologie a:before {
//   background-image: url(../images/sprite.png);
//   background-position: 0px -101px;
//   width: 38px;
//   height: 35px;
// }
// .social-share {
//   height: 150px;
// }
.webtv {
	width: 100%;
	height: 100%;
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

.header-widget-area {
	position: absolute;
	height: 100%;
	right: 0;
}

.widget_search {
	display: inline-block;
	height: 100%;
	float: right;
	padding: 0;

	input[type="search"] {
		height: 100%;
		font-size: 55px;
		display: inline-block;
		font-family: "Lato";
		font-weight: 300;
		border: none;
		outline: none;
		color: white;
		padding-right: $sidebar-visible-width;
		width: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;

		background-color: darken(map-get($theme-colors, secondary), 7%);
		z-index: 3;
		transition: all 0.4s cubic-bezier(0, 0.795, 0, 1);
		cursor: pointer;
		padding-left: $spacer * 1.5;
	}

	input[type="search"]:focus:hover {
		border-bottom: 1px solid #bbb;
	}

	input[type="search"]:focus {
		width: 700px;
		z-index: 1;
		opacity: 1;
		border-bottom: 1px solid #bbb;
		cursor: text;
	}

	input[type="submit"] {
		height: 100%;
		display: inline-block;
		float: right;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAmCAYAAABDClKtAAAACXBIWXMAAAsSAAALEgHS3X78AAABcUlEQVRYhe2XXZHCQBCEO1EQCZEQByAhEiLhJMQBEpDAOQAUHA4iAQl9D5m5G1KQ2tmfqjykH2Gm98vs32yFAJHsARwAdACOi79vAB4A7lVVfYf4JYnkSHJiuCaSYymYbgEzkTyR7Em2Jq6V305v4rucQIMxf5IcnLlPkx+cGwp0IdlEeDSSmw4mU6Y6Rxv9+52NX9xUmjVxSQUynlqxKSZ5NGvIPWUrvo1ZY6M3Was05AIy3oO7WrKl40ocPoZ+dB8SX2M+qQGg5Gms3ofVKFGN+eoAgHsRnFfvsF1otm1biojzyU+SdEGVAooZpy4NE6M/qNLT54mvMfdDQOgijJN639aCVDXmBg0I3K6RUu/HapRqi4fnMmkoAOS/ZiRxexeyGGyrdRGD7TV5YlSiHf6KBvoAlvpwIMmfLOuUeZ5YRwHKByaDJj1GZSrzg4m5VuP6BuSqVfyQWw4sRTuYVzuYVzuYVzuYVwYsuTvJKluhX3UdpEKqWiJgAAAAAElFTkSuQmCC");
		background-position: center center;
		background-repeat: no-repeat;
		text-indent: -10000px;
		border: none;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 4;
		cursor: pointer;
		pointer-events: none;
		background-color: map-get($theme-colors, secondary); //border-bottom: solid 1px darken(map-get($theme-colors, secondary), 10%);
	}

	.search-form:focus-within input[type="submit"] {
		pointer-events: initial;
	}

	input[type="submit"]:hover {
	}
}

aside.sidebar-primary {
	background-color: darken(white, 7%);

	.entry {
		background-color: white;
	}

	.widget {
		padding: $spacer;
	}

	.entry-title,
	.post-info,
	p {
		@extend .px-4;
	}

	p:last-child {
		@extend .pb-4;
	}

	.widget-title {
		color: map-get($theme-colors, dark);

		&::before {
			content: "";
			width: 2rem;
			height: 5px;
			background-color: map-get($theme-colors, primary);
			margin-bottom: 1rem;
			display: block;
		}
	}

 .featuredpost .entry-image {
		margin-bottom: 1rem;
	}
}
