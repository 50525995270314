/* # Table of Contents
- Defaults
  - Typographical Elements
  - Headings
  - Screen Reader Text
- Structure and Layout
  - Site Containers
  - Column Widths and Positions
- Common Classes
  - Genesis
  - Search Form
  - Titles
  - WordPress
- Widgets
  - Featured Content
- Plugins
  - Genesis eNews Extended
  - Jetpack
- Skip Links
- Site Header
  - Title Area
  - Widget Area
- Site Navigation
  - Accessible Menu
  - Site Header Navigation
  - Primary and Secondary Navigation
- Content Area
  - Entries
  - Entry Meta
  - Pagination
  - Comments
- Sidebars
- Site Footer
- Media Queries
  - Max-width: 1280px
  - Max-width: 1023px
  - Max-width: 880px
- Print Styles
*/


/* # Defaults
---------------------------------------------------------------------------------------------------- */


/* ## Typographical Elements
--------------------------------------------- */

a,
button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button,
.gallery img {
  transition: all 0.1s ease-in-out;
}


li {}

hr {
  border: 0;
  border-collapse: collapse;
  border-top: 1px solid #ddd;
  clear: both;
  margin: 1em 0;
}

b,
strong {
  font-weight: 700;
}

blockquote,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 40px;
}

blockquote::before {
  content: "\201C";
  display: block;
  font-size: 30px;
  height: 0;
  left: -20px;
  position: relative;
  top: -10px;
}









/* ## Headings
--------------------------------------------- */

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
//   font-weight: 700;
//   line-height: 1.2;
//   margin: 0 0 10px;
// }
// h1 {
//   font-size: 36px;
//   font-size: 3.6rem;
// }
// h2 {
//   font-size: 30px;
//   font-size: 3rem;
// }
// h3 {
//   font-size: 24px;
//   font-size: 2.4rem;
// }
// h4 {
//   font-size: 20px;
//   font-size: 2rem;
// }
// h5 {
//   font-size: 18px;
//   font-size: 1.8rem;
// }
// h6 {
//   font-size: 16px;
//   font-size: 1.6rem;
// }

/* ## Screen Reader Text
--------------------------------------------- */

.screen-reader-text,
.screen-reader-text span,
.screen-reader-shortcut {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  word-wrap: normal !important;
}

.screen-reader-text:focus,
.screen-reader-shortcut:focus,
.genesis-nav-menu .search input[type="submit"]:focus,
//.widget_search input[type="submit"]:focus 
{
  clip: auto !important;
  height: auto;
  width: auto;
  display: block;
  font-size: 1em;
  font-weight: bold;
  padding: 15px 23px 14px;
  color: #333;
  background: #fff;
  z-index: 100000;
  /* Above WP toolbar. */
  text-decoration: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
}

.more-link {
  position: relative;
  display: block;
  font-weight: 600;
  &:hover {
    color: map-get($theme-colors, secondary);
    text-decoration: none;
  }
}









/* # Structure and Layout
---------------------------------------------------------------------------------------------------- */


/* ## Site Containers
--------------------------------------------- */

.site-inner {
  clear: both;
}








/* ## Column Widths and Positions
--------------------------------------------- */


/* ### Wrapping div for .content and .sidebar-primary */

// .content-sidebar-sidebar .content-sidebar-wrap,
// .sidebar-content-sidebar .content-sidebar-wrap,
// .sidebar-sidebar-content .content-sidebar-wrap {
//  width: 980px;
// }
// .content-sidebar-sidebar .content-sidebar-wrap {
//  float: left;
// }
// .sidebar-content-sidebar .content-sidebar-wrap,
// .sidebar-sidebar-content .content-sidebar-wrap {
//  float: right;
// }
// /* ### Content */
// // .content {
// //   float: right;
// //   width: 780px;
// // }
// .content-sidebar .content,
// .content-sidebar-sidebar .content,
// .sidebar-content-sidebar .content {
//  float: left;
// }
// .content-sidebar-sidebar .content,
// .sidebar-content-sidebar .content,
// .sidebar-sidebar-content .content {
//  width: 580px;
// }
// .full-width-content .content {
//  width: 100%;
// }
// /* ### Primary Sidebar */
// .sidebar-primary {
//  float: right;
//  width: 360px;
// }
// .sidebar-content .sidebar-primary,
// .sidebar-sidebar-content .sidebar-primary {
//  float: left;
// }
// /* ### Secondary Sidebar */
// .sidebar-secondary {
//  float: left;
//  width: 180px;
// }
// .content-sidebar-sidebar .sidebar-secondary {
//  float: right;
// }

/* # Common Classes
---------------------------------------------------------------------------------------------------- */


/* ## Genesis
--------------------------------------------- */

.breadcrumb {
  margin-bottom: 40px;
}

.archive-description,
.author-box {
  margin-bottom: 60px;
}

.archive-description p:last-child,
.author-box p:last-child {
  margin-bottom: 0;
}








/* ## Search Form
--------------------------------------------- */

.search-form {
  overflow: hidden;
}

.site-header .search-form {
  float: right;
  margin-top: 12px;
}

.entry-content .search-form,
.site-header .search-form {
  width: 50%;
}

// .genesis-nav-menu .search input[type="submit"],
// .widget_search input[type="submit"] {
//   border: 0;
//   clip: rect(0, 0, 0, 0);
//   height: 1px;
//   margin: -1px;
//   padding: 0;
//   position: absolute;
//   width: 1px;
// }

/* ## Titles
--------------------------------------------- */

// .author-box-title,
// .archive-title {
//   font-size: 20px;
//   font-size: 2rem;
// }
// .entry-title {
//   font-size: 36px;
//   font-size: 3.6rem;
// }
// .entry-title a,
// .sidebar .widget-title a {
//   color: #333;
// }
// .entry-title a:hover,
// .entry-title a:focus {
//   color: #c3251d;
// }
.widget-title {
  margin-bottom: 1.25rem;
}








/* ## WordPress
--------------------------------------------- */

a.aligncenter img {
  display: block;
  margin: 0 auto;
}

a.alignnone {
  display: inline-block;
}

.alignleft {
  float: left;
  text-align: left;
}

.alignright {
  float: right;
  text-align: right;
}

a.alignleft,
a.alignnone,
a.alignright {
  max-width: 100%;
}

img.centered,
.aligncenter {
  display: block;
  margin: 0 auto 24px;
}

img.alignnone,
.alignnone {
  margin-bottom: 12px;
}

a.alignleft,
img.alignleft,
.wp-caption.alignleft {
  margin: 0 24px 24px 0;
}

a.alignright,
img.alignright,
.wp-caption.alignright {
  margin: 0 0 24px 24px;
}

.wp-caption-text {
  font-weight: 700;
  text-align: center;
}

.entry-content p.wp-caption-text {
  margin-bottom: 0;
}

.entry-content .wp-audio-shortcode,
.entry-content .wp-playlist,
.entry-content .wp-video {
  margin: 0 0 28px;
}









/* # Widgets
---------------------------------------------------------------------------------------------------- */

.widget {
  word-wrap: break-word;
}

.widget ol>li {
  list-style-position: inside;
  list-style-type: decimal;
  padding-left: 20px;
  text-indent: -20px;
}

.widget li li {
  border: 0;
  margin: 0 0 0 30px;
  padding: 0;
}

.widget_calendar table {
  width: 100%;
}

.widget_calendar td,
.widget_calendar th {
  text-align: center;
}








/* ## Featured Content
--------------------------------------------- */

// .featured-content .entry {
//   margin-bottom: 40px;
// }
.featured-content .entry:last-child {
  margin-bottom: 0;
}

.featured-content .entry-title {
  font-size: inherit;
  font-weight: 400;
}









/* ## Jetpack
--------------------------------------------- */

#wpstats {
  display: none;
}









/* # Skip Links
---------------------------------------------------------------------------------------------------- */

.genesis-skip-link {
  margin: 0;
}

.genesis-skip-link li {
  height: 0;
  width: 0;
  list-style: none;
}








/* Display outline on focus */

:focus {

  outline: 0;
}









/* # Site Header
---------------------------------------------------------------------------------------------------- */


/* ## Title Area
--------------------------------------------- */

.title-area {
  float: left;
}



.site-title {
  // font-size: 30px;
  // font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.site-title a,
.site-title a:hover,
.site-title a:focus {
  color: #333;
}

.header-image .site-title>a {
  background: url(images/logo.png) no-repeat left;
  float: left;
  min-height: 60px;
  width: 100%;
}

.site-description {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
}

.site-description,
.site-title {
  margin-bottom: 0;
}

.header-image .site-description,
.header-image .site-title a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}








/* ## Widget Area
--------------------------------------------- */

.site-header .widget-area {
  float: right;
  text-align: right;
}









/* # Site Navigation
---------------------------------------------------------------------------------------------------- */

// .genesis-nav-menu .menu-item {
//   display: inline-block;
//   text-align: left;
//   position: relative;
// }
// .genesis-nav-menu a {
//   color: map-get($theme-colors, primary);
//   text-decoration: none;
//   display: block;
// }
// .genesis-nav-menu a:hover,
// .genesis-nav-menu li.sfHover a,
// .genesis-nav-menu a:focus,
// .genesis-nav-menu .current-menu-item>a,
// .genesis-nav-menu .sub-menu .current-menu-item>a:hover,
// .genesis-nav-menu .sub-menu .current-menu-item>a:focus {
//   background-color: map-get($theme-colors, primary);
//   color: white;
// }
// .genesis-nav-menu .sub-menu {
//   left: -9999px;
//   opacity: 0;
//   position: absolute;
//   transition: opacity 0.4s ease-in-out;
//   width: 100%;
//   padding-left: 0;
//   li {
//     width: 100%;
//   }
// }
// .genesis-nav-menu .sub-menu a {
//   background-color: map-get($theme-colors, primary);
//   padding: 20px;
//   position: relative;
//   word-wrap: break-word;
//   color: #FFF;
//   &:hover {
//     color: map-get($theme-colors, secondary);
//   }
// }
// .genesis-nav-menu .sub-menu .sub-menu {
//   margin: -56px 0 0 209px;
// }
// // .genesis-nav-menu .menu-item:hover {
// //   position: static;
// // }
// .genesis-nav-menu .menu-item:hover>.sub-menu {
//   left: auto;
//   opacity: 1;
// }
// .genesis-nav-menu>.first>a {
//   padding-left: 0;
// }
// .genesis-nav-menu>.last>a {
//   padding-right: 0;
// }

/* ## Accessible Menu
--------------------------------------------- */

.menu .menu-item:focus {
  position: static;
}

.menu .menu-item>a:focus+ul.sub-menu,
.menu .menu-item.sfHover>ul.sub-menu {
  left: auto;
  opacity: 1;
}








/* ## Site Header Navigation
--------------------------------------------- */

.site-header .genesis-nav-menu li li {
  margin-left: 0;
}








/* ## Primary and Secondary Navigation
--------------------------------------------- */

// .nav-primary,
// .nav-secondary {
//   border-bottom: 1px solid #eee;
// }

/* # Content Area
---------------------------------------------------------------------------------------------------- */


/* ## Entries
--------------------------------------------- */

// .entry {
//   margin-bottom: 60px;
// }
// .entry-content ol,
// .entry-content ul {
//   margin-bottom: 28px;
//   margin-left: 40px;
// }
// .entry-content ol>li {
//   list-style-type: decimal;
// }
// .entry-content ul>li {
//   list-style-type: disc;
// }
// .entry-content ol ol,
// .entry-content ul ul {
//   margin-bottom: 0;
// }
// .entry-content code {
//   background-color: #333;
//   color: #ddd;
// }

/* ## Entry Meta
--------------------------------------------- */

// p.entry-meta {
//   font-size: 16px;
//   font-size: 1.6rem;
//   margin-bottom: 0;
// }
// .entry-header .entry-meta {
//   margin-bottom: 24px;
// }
// .entry-categories,
// .entry-tags {
//   display: block;
// }
// .entry-comments-link::before {
//   content: "\2014";
//   margin: 0 6px 0 2px;
// }

/* ## Pagination
--------------------------------------------- */

.pagination {
  clear: both;
  margin: 40px 0;
}

.pagination li {
  display: inline;
}

.pagination li a {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  padding-right: 10px;
  text-decoration: none;
}

.pagination .active a {
  color: #333;
}








/* ## Comments
--------------------------------------------- */

.comment-respond,
.entry-comments,
.entry-pings {
  margin-bottom: 60px;
}

.comment-header {
  font-size: 16px;
  font-size: 1.6rem;
}

.comment-content {
  clear: both;
  word-wrap: break-word;
}

.comment-list li {
  padding: 32px 0 0 32px;
}

.comment-list li.depth-1 {
  padding-left: 0;
}

.comment-respond input[type="email"],
.comment-respond input[type="text"],
.comment-respond input[type="url"] {
  width: 50%;
}

.comment-respond label {
  display: block;
  margin-right: 12px;
}

.entry-comments .comment-author {
  margin-bottom: 0;
}

.entry-pings .reply {
  display: none;
}









/* # Sidebars
---------------------------------------------------------------------------------------------------- */

// .sidebar {
//   font-size: 16px;
//   font-size: 1.6rem;
// }
// .sidebar li {
//   margin-bottom: 10px;
//   padding-bottom: 10px;
// }
// .sidebar p:last-child,
// .sidebar ul>li:last-child {
//   margin-bottom: 0;
// }
// .sidebar .widget {
//   margin-bottom: 60px;
// }

/* # Site Footer
---------------------------------------------------------------------------------------------------- */

.site-footer {
  //border-top: 1px solid #ddd; // font-size: 16px;
  // font-size: 1.6rem;
  // line-height: 1;
  //padding: 40px 0;
}

.site-footer p {
  margin-bottom: 0;
}









/* # Media Queries
---------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1280px) {

  // .site-inner,
  // .wrap {
  //  max-width: 960px;
  // // }
  // .content-sidebar-sidebar .content-sidebar-wrap,
  // .sidebar-content-sidebar .content-sidebar-wrap,
  // .sidebar-sidebar-content .content-sidebar-wrap {
  //   width: 720px;
  // }
  // .content,
  // .site-header .widget-area {
  //   width: 600px;
  // }
  // .sidebar-content-sidebar .content,
  // .sidebar-sidebar-content .content,
  // .content-sidebar-sidebar .content {
  //   width: 360px;
  // }
  // .sidebar-primary,
  // .title-area {
  //   width: 300px;
  // }
}

@media only screen and (max-width: 1020px) {

  // .site-inner,
  // .wrap {
  //  max-width: 800px;
  // }
  // .content,
  // .content-sidebar-sidebar .content,
  // .content-sidebar-sidebar .content-sidebar-wrap,
  // .sidebar-content-sidebar .content,
  // .sidebar-content-sidebar .content-sidebar-wrap,
  // .sidebar-primary,
  // .sidebar-secondary,
  // .sidebar-sidebar-content .content,
  // .sidebar-sidebar-content .content-sidebar-wrap,
  // .site-header .widget-area,
  // .title-area {
  //   width: 100%;
  // }
  .site-header .wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-image .site-title>a {
    background-position: center top;
  }

  .genesis-nav-menu li,
  .site-header ul.genesis-nav-menu,
  .site-header .search-form {
    float: none;
  } // .genesis-nav-menu,
  // .site-description,
  // .site-header .title-area,
  // .site-header .search-form,
  // .site-title {
  //   text-align: center;
  // }
  .genesis-nav-menu a,
  .genesis-nav-menu>.first>a,
  .genesis-nav-menu>.last>a {
    padding: 20px 16px;
  }

  .site-header .search-form {
    margin: 16px auto;
  }
}

@media only screen and (max-width: 880px) {

  // .site-inner,
  // .wrap {
  //  padding-left: 5%;
  //  padding-right: 5%;
  // }
}









/* # Print Styles
---------------------------------------------------------------------------------------------------- */

@media print {

  *,
  *::before,
  *::after {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^="javascript:"]::after,
  a[href^="#"]::after,
  .site-title>a::after {
    content: "";
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  .content,
  .content-sidebar {
    width: 100%;
  }

  button,
  input,
  select,
  textarea,
  .breadcrumb,
  .comment-edit-link,
  .comment-form,
  .comment-list .reply a,
  .comment-reply-title,
  .edit-link,
  .entry-comments-link,
  .entry-footer,
  .genesis-box,
  .header-widget-area,
  .hidden-print,
  .home-top,
  .nav-primary,
  .nav-secondary,
  .post-edit-link,
  .sidebar {
    display: none !important;
  }

  .title-area {
    text-align: center;
    width: 100%;
  }

  .site-title>a {
    margin: 0;
    text-decoration: none;
    text-indent: 0;
  }

  .site-inner {
    padding-top: 0;
    position: relative;
    top: -100px;
  }

  .author-box {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    orphans: 3;
    page-break-after: avoid;
    page-break-inside: avoid;
    widows: 3;
  }


  img {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  blockquote,
  pre,
  table {
    page-break-inside: avoid;
  }

  dl,
  ol,
  ul {
    page-break-before: avoid;
  }
}
