@import "common/_variables";
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
@import "../../node_modules/bootstrap/scss/_mixins";

.hero-accueil {
  position: relative;
  overflow: hidden;
  height: 80vh;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.land {
  fill: #0C214D;
  stroke-linejoin: round;
  stroke: #174299;
  cursor: pointer;
  &:hover {
    fill: map-get($theme-colors, secondary);
  }
}

#AUVERGNE {
  text {
    transform: translate(1100px, 152px);
  }
}

#PIEMONT {
  text {
    transform: translate(1373px, 202px);
  }
}

#AOSTE {
  text {
    transform: translate(1310px, 100px);
  }
}

#LIGURIE {
  text {
    transform: translate(1451px, 252px);
  }
}

#PACA {
  text {
    transform: translate(1171px, 292px);
  }
}

#CORSE {
  text {
    transform: translate(1481px, 437px);
  }
}

#SARDAIGNE {
  text {
    transform: translate(1465px, 612px);
  }
}

#hero-map {
  width: 100%;
  height: 100%;
  position: absolute; // svg {
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   transform: scale(1);
  // }
  text {
    font-weight: 600;
    fill: #fff; //font-size: 24px;
    stroke: white;
    text-transform: uppercase;
  }
  .textures {
    pointer-events: none;
  }
  .map {
    transform: translate(17vw, 50px);
  }
}

@include media-breakpoint-down(sm) {
  #hero-map text {
    display: none;
  }
}




// #clouds {
//   background: url(../images/clouds2.png);
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   animation: animatedBackground 100s linear infinite;
//   background-size: cover;
//   opacity: 1;
// }
@keyframes animatedBackground {
  from {
    background-position: 100% 0;
  }

  to {
    background-position: 0 0;
  }
}
