@import "../../node_modules/bourbon/core/_bourbon.scss";
@import "common/_variables";
@import "layouts/header";
@import "components/_buttons";

.content {
  width: 100%;
}

.transition-overlay {
  background-color: #fff;
  position: fixed;
  z-index: 60;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.page-title {
  background-color: map-get($theme-colors, primary);
}

.page-title h1 {
  color: white;
  margin-bottom: 0;
}

.page .page-categories {
  display: none;
}

.page-categories {
  background-color: darken(map-get($theme-colors, primary), 7%);
  display: flex;
  align-items: center;
  .post-categories {
    padding: 0;
    margin-bottom: 0;
    li {
      display: inline;
      a {
        color: white;
      }
      &:before {
        content: "#";
        color: map-get($theme-colors, secondary);
      }
    }
  }
}






/* plugins
--------------------------------------------- */

#cookie-notice {
  text-align: justify;
  margin: 10px;
  padding: 0.5rem;
  box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.5);
  right: auto;
  left: 0;
  min-width: auto;
  max-width: 200px;
  z-index: 19;
  .button {}
  .cn-set-cookie {
    @extend .btn;
    width: 100%;
    display: block;
    text-transform: uppercase;
    padding: 0.2em 0.6em;
    line-height: inherit;
    margin: .6rem 0;
    font-weight: 600;
    background-color:  map-get($theme-colors, primary);
  }
  .cn-more-info {
    color: map-get($theme-colors, primary);
    text-transform: none;
    margin-left: 0!important;
  }
  .cn-close-icon {
    top: 22px;
  }
}
