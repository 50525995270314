.btn {
  cursor: pointer;
  color: white;
  box-shadow: none;
    border:0px solid transparent; /* this was 1px earlier */

  &:hover {
    color: white;
  }
  &.loading:hover {
    color: transparent;
  }
}

.btn-secondary {
  &:hover {
    color: white;
  }
}

.wpcf7-submit.loading {
  color: transparent;
  background-image: url(../images/rolling.svg);
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-position 0s;
}
