// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue:    #174299 !default;
$light-blue: #EBF0F3 !default;
$dark-blue: #0C214D !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #f6a826 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$facebook:#2f5c93 !default;
$linkedin:#007db1 !default;
$twitter: #00a3ec !default;
$youtube:#ff001e !default; 

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  facebook: $facebook,
  linkedin: $linkedin,
  twitter: $twitter,
  youtube: $youtube
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $yellow,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  light-blue : $light-blue,
  dark-blue : $dark-blue,
  dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;
$enable-shadows:            true !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:       $white !default;
$body-color:    $gray-900 !default;

// Links
//
// Style anchor elements.
