@font-face {
    font-family: "FontAwesome";
    src: url("../fonts/FontAwesome.eot");
    src: url("../fonts/FontAwesome.eot?#iefix") format("embedded-opentype"),
         url("../fonts/FontAwesome.woff2") format("woff2"),
         url("../fonts/FontAwesome.woff") format("woff"),
         url("../fonts/FontAwesome.ttf") format("ttf"),
         url("../fonts/FontAwesome.svg#FontAwesome") format("svg");
    font-style: normal;
    font-weight: normal;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%
}
.fa-2x {
    font-size: 2em
}
.fa-3x {
    font-size: 3em
}
.fa-4x {
    font-size: 4em
}
.fa-5x {
    font-size: 5em
}
.fa-fw {
    width: 1.28571429em;
    text-align: center
}
.fa-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none
}
.fa-ul > li {
    position: relative
}
.fa-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center
}
.fa-li.fa-lg {
    left: -1.85714286em
}
.fa-border {
    padding: .2em .25em .15em;
    border: solid .08em #eee;
    border-radius: .1em
}
.fa-pull-left {
    float: left
}
.fa-pull-right {
    float: right
}
.fa.fa-pull-left {
    margin-right: .3em
}
.fa.fa-pull-right {
    margin-left: .3em
}
.pull-right {
    float: right
}
.pull-left {
    float: left
}
.fa.pull-left {
    margin-right: .3em
}
.fa.pull-right {
    margin-left: .3em
}


.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}
.fa-stack-1x,
.fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}
.fa-stack-1x {
    line-height: inherit
}
.fa-stack-2x {
    font-size: 2em
}



.fa-linkedin:before {
    content: "\f0e1"
}


.fa-twitter:before {
    content: "\f099"
}

.fa-facebook-f:before,
.fa-facebook:before {
    content: "\f09a"
}

.fa-pinterest:before {
    content: "\f0d2"
}

.fa-youtube:before {
    content: "\f167"
}


.fa-angle-left:before {
    content: "\f104"
}
.fa-angle-right:before {
    content: "\f105"
}

.fa-angle-double-right:before {
    content: "\f101"
}

.fa-bars:after {
    content: "\f0c9";
    color: #26B6BF;
    zoom: 1.5;
    margin-left: 10px;
}

.fa-lock:before {
    content: "\f023";
}

.fa-google-plus:before {
    content: "\f0d5";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-long-arrow-right:before {
  content: "\f178";
}