.footer-widgets {
  background-color: map-get($theme-colors, primary);
  ul {
    padding: 0;
    @extend .no-bullets;
  }
  ul li,
  .featured-content .entry {
    margin-bottom: $spacer;
  }
}

.bg-primary {
  a {
    color: white;
    &:hover {
      color: map-get($theme-colors, secondary);
    }
  }
}

.site-footer {
  color: white;
}
