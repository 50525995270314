// [class*="col-"] {
//   background-color: rgba(86, 61, 124, .15);
//   border: 1px solid rgba(86, 61, 124, 0.15);
// }
.home .site-inner {
  background: url(../images/pattern-white.png) white center bottom no-repeat;
}

#accueil-intro {
  position: relative;
}

#slideshow-wrap {
  height: 600px;
}

.cycle-slideshow {
  z-index: 1;
  display: inline-block;
}

.cycle-slideshow,
.cycle-thumbnail {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cycle-thumbnail,
.cycle-control-panel {
  height: 50%;
}

.cycle-control-panel {
  position: relative;
  width: 124px;
  display: flex;
  justify-content: center;
  float: right;
}

.cycle-thumbnail {
  width: 100%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.thumbnail-overlay {
  z-index: 150;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #174299;
  left: 0;
  top: 0;
  opacity: 0.8;
}



.cycle-overlay {
  position: absolute;
  top: 0;
  width: 50%;
  background-color: #ffffffde;
  z-index: 110;
  right: 0;
  h2:after {
    content: "";
    height: 1px;
    width: 40%;
    background-color: map-get($theme-colors, secondary);
    display: block;
    transform: translateX(-100%);
    margin: 1rem 0;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    bottom: 0;
    top: auto;
  }
}

#progress-bar {
  width: 50px;
}

#progress {
  height: 5px;
}

.cycle-controls {
  height: 45px;
  #next,
  #prev {
    height: 45px;
    width: 60px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    background-color: white;
    &:before,
    &:after {
      position: absolute;
      left: 35%;
      width: 2px;
      height: 30%;
      background: $gray-500;
      content: "";
      transition: background-color .3s, opacity .3s ease, -webkit-transform .2s ease;
      transition: transform .2s ease, background-color .3s, opacity .3s ease;
      backface-visibility: hidden;
    }
    &:before {
      top: 20%;
      transform: translateX(-50%) rotate(45deg);
      transform-origin: 0 100%;
    }
    &:after {
      top: 50%;
      transform: translateX(-50%) rotate(-45deg);
      transform-origin: 0 0;
    }
    &:hover {
      background-color: map-get($theme-colors, secondary);
      &:before,
      &:after {
        background-color: white;
      }
    }
  }
  #next {
    right: 0;
    transform: rotate(180deg) translateY(-50%);
    transform-origin: 50% 25%;
  }
}

.cycle-progress,
.cycle-controls {
  position: absolute;
}


.cycle-controls {
  right: 0;
  bottom: 0;
}

#cycle-caption {
  text-align: center;
  color: $gray-500;
  font-weight: 600;
  &::first-letter {
    color: map-get($theme-colors, secondary);
  }
}

.press-releases {
  article:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: white;
  }
  article:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
    background-color: #f4f4f4;
  }
  article:nth-child(3) {
    flex: 0 0 50%;
    max-width: 50%;
    background-color: #ddd;
  }
}


#accueil-intro:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 25%;
  background-color: white;
}

.news {
  height: 400px;
  .post-thumbnail {
    height: 50%;
  }
  &:nth-child(odd) {
    flex-direction: column;
    .entry-header {
      &:after {
        top: -0.5rem;
      }
    }
  }
  &:nth-child(even) {
    flex-direction: column-reverse;
    .entry-header {
      &:after {
        bottom: -0.5rem;
      }
    }
  }
  .entry-header {
    &:after {
      content: "";
      position: absolute;
      background-color: white;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg) translateX(-50%);
      left: 50%;
      transform-origin: 0;
    }
  }
}



.post-thumbnail {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.news {
  .post-thumbnail,
  .entry-header {
    flex: 1 1 auto;
    position: relative;
    height: 50%;
  }
}

.tiret-secondary-top:before {
  content: "";
  width: 2rem;
  height: 0.25rem;
  background-color: map-get($theme-colors, secondary);
  display: block;
  margin-bottom: 1rem;
}

.categorie-thumbs {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
}

.categorie-thumbnail {
  display: inline-block;
  margin: 1rem 0 1rem 1rem;
  width: 50px;
  height: 50px;
  @include hide-text;
}

.categorie-programmes-europeens {
  @include sprite($programmes-europeens-sm);
}

.categorie-innovation {
  @include sprite($innovation-sm);
}

.categorie-transports {
  @include sprite($transports-sm);
}

.categorie-tourisme {
  @include sprite($tourisme-sm);
}

.categorie-promotion {

  @include sprite($promotion-sm);
}

.icone-superficie {
  @include sprite($superficie);
}

.icone-habitants {
  @include sprite($habitants);
}

.icone-entreprise {
  @include sprite($entreprise);
}

.icone-emploi {
  @include sprite($emploi);
}

.icone-pib {
  @include sprite($pib);
}

.icone-etudiants {
  @include sprite($etudiants);
}

.event {
  .post-thumbnail {

    img {
      max-height: 200px;
    }
  }
}

.entry-meta,
.sidebar .post-info {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: map-get($theme-colors, dark);
  &:after {
    content: "";
    width: 4rem;
    height: 1px;
    background-color: map-get($theme-colors, secondary);
    display: block;
    margin-top: 0.25rem;
  }
}

.sidebar .entry-title {
  margin-bottom: 0.5rem;
  a {
    @extend h4;
  }
}


@include media-breakpoint-down(md) {
  .web-tv {
    min-height: 50vw;
  }
}
