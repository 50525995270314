  html {
    overflow-x: hidden;    
    max-width: 100%;
  }

  img {
    max-width: 100%;
    max-width: 100%;
    height: auto;
  }

  label {
    display: block;
  }

  a {
    text-decoration-skip-ink: auto;
      &:hover {
    color: map-get($theme-colors, secondary);
    text-decoration:none;
  }
  }


  .borders {
    border: 1px solid rgba(86, 61, 124, 0.15);
  } // * {
  //  transition  : all .3s ease;
  // }
  .underlined {
    position: relative;


    &:after {
      content: "";
      height: 3px;
      width: 100%;
      background-color: map-get($theme-colors, secondary);
      position: absolute;
      top: 90%;
      left: 0;
      display: block;
      transition: all .15s ease-out;
    }
  }



  a.underlined {
    color: map-get($theme-colors, dark);
    &:after {
      top: 110%;
    }

    &:hover {
      text-decoration: none;
      color: map-get($theme-colors, primary); //padding-bottom: 0;
      &:after {
        top: 90%;
      }
    }
  }

  .big {
    font-weight: 600;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;

    display: table;
  }
  /* Change Autocomplete styles in Chrome*/

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px darken(map-get($theme-colors, secondary), 7%) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .no-bullets li {
    list-style-type: none;
  }

  .site-inner {
    background: url(../images/pattern-blue.png) map-get($theme-colors, light-blue) center bottom no-repeat;
  }
