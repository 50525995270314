.wpcf7-response-output {
  margin: 2rem 0 1rem;
  padding: 1rem 1rem;
}

.wpcf7-response-output.wpcf7-mail-sent-ok {
  align-self: center;
  margin: 0;
  padding: 0 1rem;
}


.ajax-loader {
  display: none;
}


.wpcf7 .screen-reader-response {
  @include hide-text;
  position: absolute;
  height: 1px;
  width: 1px;
}

.wpcf7-not-valid-tip {
  color: #f00;
  font-size: 1em;
  font-weight: normal;
  display: block;
}

.wpcf7-validation-errors {
  border: 2px solid #f7e700;
}

.wpcf7-display-none {
  display: none;
}

#{$all-text-inputs} {
  background-color: transparent;
  border: none;
  border-bottom: solid 2px map-get($theme-colors, primary);
  color: map-get($theme-colors, dark);
  outline: 0;
  width: 100%;
  &:focus {
    border-bottom-color: map-get($theme-colors, secondary);
  }
}

::placeholder {
  color: light;
  transition: opacity .15s ease;
}

.bg-primary {
  #{$all-text-inputs} {
    border-bottom: solid 2px white;
    color: white;
    &:focus {
      border-bottom-color: map-get($theme-colors, secondary);
    }
  }
   ::placeholder {
    color: white;
    transition: opacity .15s ease;
  }
}



:focus::placeholder {
  opacity: 0.5;
}

form label {
  color: transparent;
}

textarea {
  resize: none;
}

.form-control,
.form-control:focus {
  width: auto; // background-color: map-get($theme-colors, secondary);
  // color: white;
}

.error404 .search-form {
  padding-bottom: $spacer*2;
  display: flex;
}
