/* Reveal Effect
---------------------------------------------------------------------------------------------------- */

// .scroll-reveal {
//   opacity: 0;
//   &.is-revealed {
//     opacity: 1;
//   }
//   .btn,
//   .button,
//   button,
//   .voir,
//   .image-container,
//   .article,
//   p:not(".sstitle, .split-line, .entry-meta"),
//   {
//     opacity: 0;
//   }
// }

// .line {
//   overflow: hidden;
// }

// .image-container {
//   position: relative;
//   pointer-events: none;
//   transition: opacity 0.3s ease, box-shadow .3s ease .6s;
//   overflow: hidden;
//   z-index: 1;
//   &.is-visible {
//     pointer-events: all;
//     opacity: 1;
//   }
// }

// .image-container {
//   &.is-visible {
//     box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
//   }
// }

// .image-container.is-visible .mask {
//   margin-right: 0;
//   right: 0;
//   bottom: 0;
//   left: 100%;
// }

// .image-container .mask {
//   width: 100%;
//   height: 100%;
//   margin: 0 -10px 0 0;
//   position: absolute;
//   top: 0;
//   right: 100%;
//   bottom: 100%;
//   left: 0;
//   background: map-get($theme-colors, dark);
//   z-index: 3;
//   transition-delay: 0s, 0.3s, 0.3s, 0.6s;
//   transition: bottom 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, right 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.3s, margin 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.3s, left 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.6s;
//   overflow: hidden;
// }

// .image-container.is-visible>*:not(.mask) {
//   filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
//   opacity: 1;
//   -moz-transform: translate3d(0, 0, 0);
//   -webkit-transform: translate3d(0, 0, 0);
//   transform: translate3d(0, 0, 0);
// }

// .image-container>*:not(.mask) {
//   left: 0;
//   top: 0;
//   filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
//   opacity: 0;
//   transform: translate3d(0, 0, 0);
//   transition: opacity 0s linear 0.6s, transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s;
// }

// .is-visible {
//   visibility: visible;
//   opacity: 1;
// }
