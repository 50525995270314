@import "../common/_variables";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/_mixins";
@import "../../node_modules/bourbon/core/_bourbon.scss";




/* Site Header
---------------------------------------------------------------------------------------------------- */

.site-header {
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.5);
  background: white;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 50; // position: fixed;
  transition: transform .5s ease, background .3s, -webkit-transform .5s ease;
  .container {
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-up(lg) {
    position: relative;
  }
}



.site-header.hidden {
  transform: translateY(-100%);
}

.site-header.shrink {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  nav a span {
    color: $body-color;
  }
} // .admin-bar .site-header {
//   top: 32px;
// }

/* Hero page header
--------------------------------------------- */

.hero-section {
  color: #fff; //padding: 120px 0 60px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-color: map-get($theme-colors, light-blue);
  height: 40vmin;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
}

.no-thumb .hero-section {
  height: 20vmin;
}

.parent-pageid-95 .hero-section {
  background-size: auto;
  background-color: white;
}





/* logo
--------------------------------------------- */

.site-title a {
  background-image: url("../images/alpmed-logo.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  display: inline-flex;
  @include hide-text;
  height: 90px;
  width: 186px;
  @include media-breakpoint-down(md) {
    height: 45px;
    width: 93px;
  }
}





/* Site Title and Description
--------------------------------------------- */

.site-title {
  font-family: $font-family-sans-serif;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: 0;
}


.header-image .nav-primary:not(button) {
  margin-top: 10px;
}

.header-image .site-header {
  padding: 9px 40px;
  .wrap {
    padding: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 0 40px;
  }
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @include media-breakpoint-down(xs) {

    padding: 0 20px;
  }
} // .header-image .site-header.shrink {
//     padding: 0 40px;
// }
.header-image .site-title {
  display: inline;
  text-indent: -9999px;
}

.header-image .site-title>a {
  background-position: center center !important;
  background-size: contain !important;
  float: left;
}

.header-image .title-area {
  margin: 0;
}

.site-description {
  display: block;
  height: 0;
  margin-bottom: 0;
  text-indent: -9999px;
}





/* Site Navigation
---------------------------------------------------------------------------------------------------- */

.genesis-nav-menu {
  margin: 0;
  clear: both;
  font-weight: 700;
  line-height: 1;
  width: 100%;
} // .genesis-nav-menu .menu-item {
//     display: inline-block;
//     text-align: left;
// }
// .genesis-nav-menu a {
//     border-bottom-width: 0;
//     color: $black;
//     display: block;
//     font-family: $main-font;
//     @include rem(font-size, 1.4rem);
//     font-weight: 600;
//     letter-spacing: 0.5px;
//     padding: 20px;
//     text-transform: uppercase;
// }
// .genesis-nav-menu a:focus,
// .genesis-nav-menu a:hover {
//     color: $couleur2;
// }
// .genesis-nav-menu .sub-menu {
//     background-color: #fff;
//     left: -9999px;
//     opacity: 0;
//     position: absolute;
// }
// .genesis-nav-menu .sub-menu,
// .genesis-nav-menu .sub-menu a {
//     width: 180px;
// }
// .genesis-nav-menu .sub-menu .sub-menu {
//     margin: -44px 0 0 179px;
// }
// .genesis-nav-menu .sub-menu a {
//     border: 1px solid #eee;
//     border-top: 0;
//     padding: 15px;
//     position: relative;
// }
// .genesis-nav-menu .menu-item:hover {
//     position: static;
// }
// .genesis-nav-menu .menu-item:hover>.sub-menu {
//     left: auto;
//     opacity: 1;
// }
// .header-image {
//     .nav-primary:not(button),
//     .nav-secondary {
//         @include media-breakpoint-down(sm) {
//             margin-top: 54px;
//         }
//     }
// }

/* Accessible Menu
--------------------------------------------- */

.menu .menu-item:focus {
  position: static;
}

.menu .menu-item>a:focus+ul.sub-menu,
.menu .menu-item.sfHover>ul.sub-menu {
  left: auto;
  opacity: 1;
}





/* Primary Navigation
--------------------------------------------- */

// .nav-primary {
//   float: right;
// }
.menu-primary {
  display: flex;
}

.nav-primary .genesis-nav-menu li.last a {
  padding-right: 0;
}



.nav-primary .genesis-nav-menu li li {
  margin-left: 0;
} // ul#menu-left,
// ul#menu-right {
//     @include media-breakpoint-down(sm) {
//         display: flex;
//         justify-content: center;
//     }
// }

/* Secondary Navigation
--------------------------------------------- */

.nav-secondary {
  margin-top: 10px;
  float: right;
}

.nav-secondary .genesis-nav-menu a {
  padding: 20px;
  @media only screen and (max-width: 360px) {
    padding: 20px 10px;
  }
}





/* Responsive Navigation
--------------------------------------------- */

#vertical-navigation {
  display: none
}

.menu-themes-strategiques,
.menu-temi-strategici {
  display: inline-block;
}

.search-form {
  display: none
}

.site-header {
  .nav-primary {
    width: 100vw;
    margin: auto;
    padding: 5vw;
    background-color: map-get($theme-colors, primary);
    border-top: .2rem solid #f0f1f2;
    border-bottom: .2rem solid #f0f1f2;
    position: fixed;
    overflow: auto;
    overflow-x: visible;
    left: 5vw;
    top: -1rem;
    left: -1rem;
    right: -1rem;
    bottom: -1rem;
    display: none;
  }

  .menu {
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: $h3-font-size;
    display: flex;
    clear: both;
    text-align: center;
    a {
      color: white;
    }
  }

  .sub-menu {
    display: none;
    font-size: 1.4rem;
    padding: 1em 0;
    clear: both;
    font-size: $h4-font-size;
    li {
      width: 100%;
    }
  }

  .menu-item {
    display: block;
    width: 100%;
    position: relative; //font-size: $h3-font-size;
    padding: $spacer*0.5 0;
    a {
      text-decoration: none;
      width: auto; //display: inline-block;
      display: inline-block;
      padding: $spacer*0.5 0;
    }
    a:hover,
    li.sfHover a,
    a:focus,
    .current-menu-item>a,
    .sub-menu .current-menu-item>a:hover,
    .sub-menu .current-menu-item>a:focus {
      background-color: map-get($theme-colors, primary);
      color: map-get($theme-colors, secondary);
    }
    &.sfHover .sub-menu a:hover {
      color: map-get($theme-colors, secondary);
    }
  } // .genesis-nav-menu a {
  //   color: map-get($theme-colors, primary);
  //   text-decoration: none;
  //   display: block;
  // }
  // .genesis-nav-menu a:hover,
  // .genesis-nav-menu li.sfHover a,
  // .genesis-nav-menu a:focus,
  // .genesis-nav-menu .current-menu-item>a,
  // .genesis-nav-menu .sub-menu .current-menu-item>a:hover,
  // .genesis-nav-menu .sub-menu .current-menu-item>a:focus {
  //   background-color: map-get($theme-colors, primary);
  //   color: white;
  // }
  // .genesis-nav-menu .sub-menu {
  //   left: -9999px;
  //   opacity: 0;
  //   position: absolute;
  //   transition: opacity 0.4s ease-in-out;
  //   width: 100%;
  //   padding-left: 0;
  //   li {
  //     width: 100%;
  //   }
  // }
  .menu-toggle,
  .sub-menu-toggle {
    background: none;
    border-radius: 0;
    border-width: 0;
    color: map-get($theme-colors, primary);

    cursor: pointer;
  }

  .menu-toggle:hover,
  .menu-toggle:focus,
  .sub-menu-toggle:hover,
  .sub-menu-toggle:focus {
    background: none;
    border-width: 0;
  }

  .menu-toggle {
    padding-bottom: 0;
    position: relative;
    z-index: 19;
    position: absolute;
    right: 5vw;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    font-size: 21px;
    z-index: 999;
  } // .menu-toggle:before,
  // .menu-toggle.activated:before {
  //   color: #1e1e1e;
  //   content: "\f130";
  //   display: inline-block;
  //   font: normal 20px/1 'ionicons';
  //   margin: 0 auto;
  //   padding-right: 10px;
  //   text-rendering: auto;
  //   transform: translate(0, 0);
  //   vertical-align: middle;
  // }
  .menu-toggle.activated {
    position: fixed;
    span {
      background: 0 0;
    }
  }

  .menu-toggle span {
    display: block;
    float: right;
    position: relative;
    height: .2rem;
    background: map-get($theme-colors, primary);
    transition: background 0s .2s;
    width: 1.5rem;
    margin-left: .2em;
    margin-top: 0.9rem;
    border-radius: 2px;
  }

  .menu-toggle.activated span::before {
    top: 0;
    transform: rotate(45deg);
    transition: top .2s 0s, -webkit-transform .2s .2s;
    transition: top .2s 0s, transform .2s .2s;
    transition: top .2s 0s, transform .2s .2s, -webkit-transform .2s .2s;
  }

  .menu-toggle span::before {
    top: -4px;
    top: -.4rem;
    transition: top .2s .2s, -webkit-transform .2s 0s;
    transition: top .2s .2s, transform .2s 0s;
    transition: top .2s .2s, transform .2s 0s, -webkit-transform .2s 0s;
  }

  .menu-toggle span::after,
  .menu-toggle span::before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: .2rem;
    background-color: map-get($theme-colors, primary);
    border-radius: 2px;
    content: " ";
  }

  .menu-toggle.activated span::before,
  .menu-toggle.activated span::after {
    background-color: white;
  }

  .menu-toggle.activated span::after {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom .2s 0s, -webkit-transform .2s .2s;
    transition: bottom .2s 0s, transform .2s .2s;
    transition: bottom .2s 0s, transform .2s .2s, -webkit-transform .2s .2s;
  }

  .menu-toggle span::after {
    bottom: -.4rem;
    transition: bottom .2s .2s, -webkit-transform .2s 0s;
    transition: bottom .2s .2s, transform .2s 0s;
    transition: bottom .2s .2s, transform .2s 0s, -webkit-transform .2s 0s;
  }

  .sub-menu-toggle {
    position: absolute;
    background-color: transparent;
    padding: .5em;
    margin: 0 -1em 0 1em;
    &:before {
      content: "";
      display: block;
      margin: .2rem 0 -.2rem;
      border: .4rem solid transparent;
      border-top-color: #f6f7f8;
    }
  }
}


@include media-breakpoint-up(lg) {
  .site-header {
    .nav-primary {
      background-color: transparent;
      border: none;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: auto;
      display: block;
      margin: 0;
      padding: 0;
      overflow: initial;
      font-size: initial;
      width: 100%;
    }
    .menu-item.sfHover>.sub-menu,
    .menu-item:focus>.sub-menu,
    .menu-item:hover>.sub-menu {
      opacity: 1!important;
      pointer-events: all;
    }
    .menu {
      flex-direction: row; // justify-content: center;
      justify-content: space-between;
      text-align: left;
      padding-right: 38px;
      a {
        color: map-get($theme-colors, primary);
        padding: map-get($spacers, 5) 0;
        height: 100%;
        display: block;
        text-align: center;
        align-items: center;
        &:hover {
          color: white;
        }
      }
    }
    .sub-menu {
      background-color: map-get($theme-colors, primary);
      color: white;
      position: absolute;
      width: 18rem;
      a {
        padding: 1rem;
        position: relative;
        word-wrap: break-word;
        color: white;
        text-align: left;
      }
    }
    .menu-item {
      display: inline-block;
      padding: 0;
      width: auto;
      font-size: initial;
      flex-grow: 1;

      &.sfHover {
        background-color: map-get($theme-colors, primary);
        a {
          color: white;
        }
      }
    }

    .menu-item-has-children>a:after {
      content: "▼";
      margin-left: 0.5rem;
      font-size: 12px;
    }
    .menu-toggle,
    .sub-menu-toggle {
      display: none;
    } // @media (min-width: 896px) {
    //   .menu-toggle {
    //     display: none;
    //   }
    // }
    // .menu-toggle {
    //   padding: 27px 0;
    //   padding: 2.7rem 0;
    //   cursor: pointer;
    //   background-color: transparent;
    //   display: block;
    //   position: absolute;
    //   right: 5vw;
    //   top: 0;
    //   line-height: 1;
    //   color: transparent;
    //   letter-spacing: -.5em;
    //   z-index: 999;
    // }
    .menu-themes-strategiques,
    .menu-temi-strategici,
    .lang-item {
      display: none;
    }
  }

  #vertical-navigation {
    display: flex;
  }

  .search-form {
    display: block;
  }
}

.menu-item-has-children {
  cursor: default;
}
