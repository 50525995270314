// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$emploi-name: 'emploi';
$emploi-x: 65px;
$emploi-y: 68px;
$emploi-offset-x: -65px;
$emploi-offset-y: -68px;
$emploi-width: 63px;
$emploi-height: 64px;
$emploi-total-width: 257px;
$emploi-total-height: 256px;
$emploi-image: '../images/sprite.png';
$emploi: (65px, 68px, -65px, -68px, 63px, 64px, 257px, 256px, '../images/sprite.png', 'emploi', );
$entreprise-name: 'entreprise';
$entreprise-x: 65px;
$entreprise-y: 0px;
$entreprise-offset-x: -65px;
$entreprise-offset-y: 0px;
$entreprise-width: 65px;
$entreprise-height: 67px;
$entreprise-total-width: 257px;
$entreprise-total-height: 256px;
$entreprise-image: '../images/sprite.png';
$entreprise: (65px, 0px, -65px, 0px, 65px, 67px, 257px, 256px, '../images/sprite.png', 'entreprise', );
$etudiants-name: 'etudiants';
$etudiants-x: 130px;
$etudiants-y: 0px;
$etudiants-offset-x: -130px;
$etudiants-offset-y: 0px;
$etudiants-width: 65px;
$etudiants-height: 65px;
$etudiants-total-width: 257px;
$etudiants-total-height: 256px;
$etudiants-image: '../images/sprite.png';
$etudiants: (130px, 0px, -130px, 0px, 65px, 65px, 257px, 256px, '../images/sprite.png', 'etudiants', );
$habitants-name: 'habitants';
$habitants-x: 195px;
$habitants-y: 0px;
$habitants-offset-x: -195px;
$habitants-offset-y: 0px;
$habitants-width: 62px;
$habitants-height: 66px;
$habitants-total-width: 257px;
$habitants-total-height: 256px;
$habitants-image: '../images/sprite.png';
$habitants: (195px, 0px, -195px, 0px, 62px, 66px, 257px, 256px, '../images/sprite.png', 'habitants', );
$innovation-sm-name: 'innovation-sm';
$innovation-sm-x: 0px;
$innovation-sm-y: 133px;
$innovation-sm-offset-x: 0px;
$innovation-sm-offset-y: -133px;
$innovation-sm-width: 50px;
$innovation-sm-height: 50px;
$innovation-sm-total-width: 257px;
$innovation-sm-total-height: 256px;
$innovation-sm-image: '../images/sprite.png';
$innovation-sm: (0px, 133px, 0px, -133px, 50px, 50px, 257px, 256px, '../images/sprite.png', 'innovation-sm', );
$innovation-name: 'innovation';
$innovation-x: 36px;
$innovation-y: 183px;
$innovation-offset-x: -36px;
$innovation-offset-y: -183px;
$innovation-width: 20px;
$innovation-height: 32px;
$innovation-total-width: 257px;
$innovation-total-height: 256px;
$innovation-image: '../images/sprite.png';
$innovation: (36px, 183px, -36px, -183px, 20px, 32px, 257px, 256px, '../images/sprite.png', 'innovation', );
$loupe-name: 'loupe';
$loupe-x: 150px;
$loupe-y: 133px;
$loupe-offset-x: -150px;
$loupe-offset-y: -133px;
$loupe-width: 37px;
$loupe-height: 38px;
$loupe-total-width: 257px;
$loupe-total-height: 256px;
$loupe-image: '../images/sprite.png';
$loupe: (150px, 133px, -150px, -133px, 37px, 38px, 257px, 256px, '../images/sprite.png', 'loupe', );
$pib-name: 'pib';
$pib-x: 0px;
$pib-y: 0px;
$pib-offset-x: 0px;
$pib-offset-y: 0px;
$pib-width: 65px;
$pib-height: 68px;
$pib-total-width: 257px;
$pib-total-height: 256px;
$pib-image: '../images/sprite.png';
$pib: (0px, 0px, 0px, 0px, 65px, 68px, 257px, 256px, '../images/sprite.png', 'pib', );
$programmes-europeens-sm-name: 'programmes-europeens-sm';
$programmes-europeens-sm-x: 128px;
$programmes-europeens-sm-y: 68px;
$programmes-europeens-sm-offset-x: -128px;
$programmes-europeens-sm-offset-y: -68px;
$programmes-europeens-sm-width: 50px;
$programmes-europeens-sm-height: 50px;
$programmes-europeens-sm-total-width: 257px;
$programmes-europeens-sm-total-height: 256px;
$programmes-europeens-sm-image: '../images/sprite.png';
$programmes-europeens-sm: (128px, 68px, -128px, -68px, 50px, 50px, 257px, 256px, '../images/sprite.png', 'programmes-europeens-sm', );
$programmes-europeens-name: 'programmes-europeens';
$programmes-europeens-x: 187px;
$programmes-europeens-y: 133px;
$programmes-europeens-offset-x: -187px;
$programmes-europeens-offset-y: -133px;
$programmes-europeens-width: 36px;
$programmes-europeens-height: 37px;
$programmes-europeens-total-width: 257px;
$programmes-europeens-total-height: 256px;
$programmes-europeens-image: '../images/sprite.png';
$programmes-europeens: (187px, 133px, -187px, -133px, 36px, 37px, 257px, 256px, '../images/sprite.png', 'programmes-europeens', );
$promotion-sm-name: 'promotion-sm';
$promotion-sm-x: 100px;
$promotion-sm-y: 133px;
$promotion-sm-offset-x: -100px;
$promotion-sm-offset-y: -133px;
$promotion-sm-width: 50px;
$promotion-sm-height: 50px;
$promotion-sm-total-width: 257px;
$promotion-sm-total-height: 256px;
$promotion-sm-image: '../images/sprite.png';
$promotion-sm: (100px, 133px, -100px, -133px, 50px, 50px, 257px, 256px, '../images/sprite.png', 'promotion-sm', );
$promotion-name: 'promotion';
$promotion-x: 0px;
$promotion-y: 219px;
$promotion-offset-x: 0px;
$promotion-offset-y: -219px;
$promotion-width: 27px;
$promotion-height: 37px;
$promotion-total-width: 257px;
$promotion-total-height: 256px;
$promotion-image: '../images/sprite.png';
$promotion: (0px, 219px, 0px, -219px, 27px, 37px, 257px, 256px, '../images/sprite.png', 'promotion', );
$superficie-name: 'superficie';
$superficie-x: 0px;
$superficie-y: 68px;
$superficie-offset-x: 0px;
$superficie-offset-y: -68px;
$superficie-width: 65px;
$superficie-height: 65px;
$superficie-total-width: 257px;
$superficie-total-height: 256px;
$superficie-image: '../images/sprite.png';
$superficie: (0px, 68px, 0px, -68px, 65px, 65px, 257px, 256px, '../images/sprite.png', 'superficie', );
$tourisme-sm-name: 'tourisme-sm';
$tourisme-sm-x: 195px;
$tourisme-sm-y: 66px;
$tourisme-sm-offset-x: -195px;
$tourisme-sm-offset-y: -66px;
$tourisme-sm-width: 50px;
$tourisme-sm-height: 50px;
$tourisme-sm-total-width: 257px;
$tourisme-sm-total-height: 256px;
$tourisme-sm-image: '../images/sprite.png';
$tourisme-sm: (195px, 66px, -195px, -66px, 50px, 50px, 257px, 256px, '../images/sprite.png', 'tourisme-sm', );
$tourisme-name: 'tourisme';
$tourisme-x: 0px;
$tourisme-y: 183px;
$tourisme-offset-x: 0px;
$tourisme-offset-y: -183px;
$tourisme-width: 36px;
$tourisme-height: 36px;
$tourisme-total-width: 257px;
$tourisme-total-height: 256px;
$tourisme-image: '../images/sprite.png';
$tourisme: (0px, 183px, 0px, -183px, 36px, 36px, 257px, 256px, '../images/sprite.png', 'tourisme', );
$transports-sm-name: 'transports-sm';
$transports-sm-x: 50px;
$transports-sm-y: 133px;
$transports-sm-offset-x: -50px;
$transports-sm-offset-y: -133px;
$transports-sm-width: 50px;
$transports-sm-height: 50px;
$transports-sm-total-width: 257px;
$transports-sm-total-height: 256px;
$transports-sm-image: '../images/sprite.png';
$transports-sm: (50px, 133px, -50px, -133px, 50px, 50px, 257px, 256px, '../images/sprite.png', 'transports-sm', );
$transports-name: 'transports';
$transports-x: 223px;
$transports-y: 133px;
$transports-offset-x: -223px;
$transports-offset-y: -133px;
$transports-width: 28px;
$transports-height: 36px;
$transports-total-width: 257px;
$transports-total-height: 256px;
$transports-image: '../images/sprite.png';
$transports: (223px, 133px, -223px, -133px, 28px, 36px, 257px, 256px, '../images/sprite.png', 'transports', );
$spritesheet-width: 257px;
$spritesheet-height: 256px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($emploi, $entreprise, $etudiants, $habitants, $innovation-sm, $innovation, $loupe, $pib, $programmes-europeens-sm, $programmes-europeens, $promotion-sm, $promotion, $superficie, $tourisme-sm, $tourisme, $transports-sm, $transports, );
$spritesheet: (257px, 256px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
