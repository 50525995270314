@import "components/_font-awesome";

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirNextLTPro-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/AvenirNextLTPro-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/AvenirNextLTPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
  /* Legacy iOS */
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirNextLTPro-Medium.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/AvenirNextLTPro-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/AvenirNextLTPro-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/AvenirNextLTPro-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/AvenirNextLTPro-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/AvenirNextLTPro-Medium.svg#AvenirNextLTPro-Medium') format('svg');
  /* Legacy iOS */
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirNextLTPro-Demi.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/AvenirNextLTPro-Demi.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/AvenirNextLTPro-Demi.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/AvenirNextLTPro-Demi.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
  /* Legacy iOS */
  font-weight: 600;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirNextLTPro-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/AvenirNextLTPro-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/AvenirNextLTPro-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/AvenirNextLTPro-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  /* Legacy iOS */
  font-weight: 700;
}

body {
  font-family: "Avenir";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: map-get($theme-colors, primary);
  font-weight: 600;
}

// html {
//   font-size: 14px;
// }
// @include media-breakpoint-up(sm) {
//   html {
//     font-size: 16px;
//   }
// }
// @include media-breakpoint-up(md) {
//   html {
//     font-size: 20px;
//   }
// }
// @include media-breakpoint-up(lg) {
//   html {
//     font-size: 28px;
//   }
// }

.display-4{
  font-weight: 500;
}